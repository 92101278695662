import {  Route, Routes,useLocation } from "react-router-dom";
import Nav  from "./Nav";
import {useEffect} from 'react';
import Footer  from "./Footer";
import Login from "./auth/Login";
import Home from "./marketing/Home";
import About from "./marketing/About";
import Blog from "./marketing/Blog";
import Faq from "./marketing/Faq";
import NotFound from "./marketing/Notfound";
import Pricing from "./marketing/Pricing";
import Contact from "./marketing/Contact";
import { QueryClient, QueryClientProvider, } from 'react-query'
import PrivateRoute from './user/PrivateRoute';
import Dashboard from './user/Dashboard';
import Contents_crud from './user/settings/Contents_crud';
import Add_sectioncontents from './user/settings/Add_sectioncontents';
import Add_Accordion from './user/settings/Add_Accordion';
import Edit_accordion from './user/settings/Edit_accordion';
import Edit_content from './user/settings/Edit_content';
import Accordions from './user/settings/Accordions_crud';
import Review_crud from './user/settings/Review_crud';
import Add_carousel from './user/settings/Add_carousel';
import Eventwizard from './user/Eventwizard';
import Features from './marketing/Features';
import Gdpr from './marketing/Gdpr';
import Help_centre from './marketing/Help_centre';
import School_events from './marketing/School_events';
import Privacy_policy from './marketing/Privacy_policy';
import Trems from './marketing/Trems';
import Sell_tickets_online from './marketing/Sell_tickets_online';
import Sell_tickets_wordpress from './marketing/Sell_tickets_wordpress';
import Sell_time_slot_tickets from './marketing/Sell_time_slot_tickets';
import Eventbrite_alternative from './marketing/Eventbrite_alternative';
import How_tosell from './marketing/How_tosell_ticket';
import How_toget_vendors from './marketing/How_toget_vendors';
import ReactGA from 'react-ga';
const queryClient = new QueryClient()
function App() {
  const location = useLocation();
  ReactGA.initialize('G-VDQQQ141MN');
  useEffect(() => {
    console.log(location.pathname + location.search )
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <QueryClientProvider client={queryClient}>


        <div className="App">
          
          <Nav/>
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/hello" element={<div>hello</div>}/>
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="terms-of-use" element={<Trems />} />
            <Route path="sell-tickets" element={<How_tosell/>} />
            <Route path="sell-tickets-online" element={<Sell_tickets_online/>} />
            <Route path="school-events" element={<School_events />} />
            <Route path="sell-tickets-on-wordpress" element={<Sell_tickets_wordpress />} />
            <Route path="sell-time-slot-tickets" element={<Sell_time_slot_tickets />} />
            <Route path="eventbrite-alternative" element={<Eventbrite_alternative />} />
            <Route path="how-to-get-vendors" element={<How_toget_vendors />} />
            <Route path="privacy-policy" element={<Privacy_policy />} />
            <Route path="gdpr" element={<Gdpr />} />
            <Route path="coconut-tickets-community-help-centre" element={<Help_centre />} />
            <Route path="features" element={<Features />} />
            <Route path="/ctslogin" element={<Login />} />
            <Route path="/Signup" element={<div>Sign up</div>}/>
       
            <Route path='*' element={<NotFound />}/>
            <Route path="/*" element={<PrivateRoute />}>
              <Route path="dashboard" element={<Dashboard title="Dashboard" />} />
              <Route path="dashboard3" element={<Dashboard />} />
              <Route path="group-wizard" element={<Dashboard />} />
              <Route path="data-maintenance" element={<Dashboard />} />
              <Route path="ct-sales" element={<Dashboard />} />
              <Route path="manage_bookings" element={<Dashboard />} />
              <Route path="manage_orders" element={<Dashboard />} />
              <Route path="manage_bids" element={<Dashboard />} />
              <Route path="manual_acceptance" element={<Dashboard />} />
              <Route path="manage_manual_payments" element={<Dashboard />} />
              <Route path="ops_booking" element={<Dashboard />} />
              <Route path="vendor_gallery" element={<Dashboard />} />
              <Route path="event-wizard" element={<Eventwizard />} />
              <Route path="profile" element={<Dashboard />} />
              <Route path="subscription" element={<Dashboard />} />
              <Route path="subscription/new-card" element={<Dashboard />} />
              <Route path="password/change" element={<Dashboard />} />
              <Route path="coconut-tickets-community-help-center" element={<Dashboard />} />
              <Route path="Helpdesk_support_requests" element={<Dashboard />} />
              <Route path="ct_email_archive" element={<Dashboard />} />
              
              <Route path="section_contents" element={<Contents_crud />} />
              <Route path="add_sectioncontents" element={<Add_sectioncontents />} />
              <Route path="accordion_settings" element={<Accordions />} />
              <Route path="carousel_settings" element={<Dashboard />} />
              <Route path="add_carousel" element={<Add_carousel />} />
              <Route path="add_faq" element={<Add_Accordion />} />
              <Route path="edit_faq/:id" element={<Edit_accordion />} />
              <Route path="edit_content/:id" element={<Edit_content />} />
              <Route path="review_settings" element={<Review_crud />} />
            
            </Route>
          </Routes>
          <Footer/>
        </div>
    </QueryClientProvider>
  );
}

export default App;
